/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createDidacticMaterial = async data => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/didacticMaterials',
      data
    });

    if (res.status === 201) {
      showAlert('success', 'Het didactisch materiaal is succesvol toegevoegd');
      window.setTimeout(() => {
        location.assign('/controlpanel/didacticMaterials/overview');
      }, 1500);
    }
  } catch (err) {
    document.getElementById('createDidacticMaterial').disabled = false;
    document.getElementById('createDidacticMaterial').value = 'Voeg toe';
    showAlert('error', err.response.data.message);
  }
};

export const updateDidacticMaterial = async data => {
  const id = data.get('_id');
  const role = data.get('role');
  let redirect;

  if (role === 'user') {
    redirect = '/account/department/didacticMaterials/overview';
  } else {
    redirect = '/controlpanel/didacticMaterials/overview';
  }

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/didacticMaterials/${id}`,
      data
    });

    if (res.status === 200) {
      showAlert('success', 'Het didactisch materiaal is succesvol gewijzigd');
      window.setTimeout(() => {
        location.assign(redirect);
      }, 1500);
    }
  } catch (err) {
    document.getElementById('updateDidacticMaterial').disabled = false;
    document.getElementById('updateDidacticMaterial').value = 'Pas aan';
    showAlert('error', err.response.data.message);
  }
};

export const deleteDidacticMaterial = async id => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/didacticMaterials/${id}`
    });

    if (res.status === 204) {
      showAlert('success', 'Het didactisch materiaal is succesvol verwijderd');
      location.reload(true);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
