/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createUser = async data => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: {
        name: data.get('name'),
        email: data.get('email'),
        department: data.getAll('department'),
        password: data.get('password'),
        passwordConfirm: data.get('passwordConfirm')
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', `Gebruiker is succesvol toegevoegd`);
      window.setTimeout(() => {
        location.assign('/controlpanel/users/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateUser = async data => {
  const id = data.get('_id');

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${id}`,
      data: {
        name: data.get('name'),
        role: data.get('role'),
        department: data.getAll('department')
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', `Gebruiker is succesvol aangepast`);
      window.setTimeout(() => {
        location.assign('/controlpanel/users/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const deleteUser = async id => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/users/${id}`
    });

    if (res.status === 204) {
      showAlert('success', 'De gebruiker is succesvol verwijderd');
      location.reload(true);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

/*
export const updateUserSettings = async data => {
  const id = data.get('_id');

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${id}`,
      data: {
        email: data.get('email')
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', `Gebruiker is succesvol aangepast`);
      window.setTimeout(() => {
        location.assign('/account/settings');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
*/

export const updateUserPassword = async (
  passwordCurrent,
  password,
  passwordConfirm
) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/users/updateMyPassword',
      data: {
        passwordCurrent,
        password,
        passwordConfirm
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', `Paswoord succesvol aangepast`, 5);
      window.setTimeout(() => {
        location.reload(true);
      }, 5000);
    }
  } catch (err) {
    showAlert('error', err.response.data.message, 5);
  }
};

export const sendResetEmail = async email => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotPassword',
      data: {
        email
      }
    });

    if (res.data.status === 'success') {
      showAlert(
        'success',
        `Indien het e-mailadres bestaat zal je zo dadelijk een e-mail ontvangen met verdere instructies`,
        5
      );
      window.setTimeout(() => {
        location.assign('/login');
      }, 5000);
    }
  } catch (err) {
    showAlert('error', err.response.data.message, 5);
  }
};

export const resetUserPassword = async data => {
  const token = data.get('token');
  const password = data.get('password');
  const passwordConfirm = data.get('passwordConfirm');

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/resetPassword/${token}`,
      data: {
        password,
        passwordConfirm
      }
    });

    if (res.data.status === 'success') {
      showAlert('success', `Paswoord succesvol aangepast`, 5);
      window.setTimeout(() => {
        location.assign('/login');
      }, 5000);
    }
  } catch (err) {
    showAlert('error', err.response.data.message, 5);
  }
};
