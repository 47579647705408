/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createLearningBook = async data => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/learningBooks',
      data
    });

    if (res.status === 201) {
      showAlert('success', 'Het leerboek is succesvol toegevoegd');
      window.setTimeout(() => {
        location.assign('/controlpanel/learningBooks/overview');
      }, 1500);
    }
  } catch (err) {
    document.getElementById('createLearningBook').disabled = false;
    document.getElementById('createLearningBook').value = 'Voeg toe';
    showAlert('error', err.response.data.message);
  }
};

export const updateLearningBook = async data => {
  const id = data.get('_id');
  const role = data.get('role');
  let redirect;

  if (role === 'user') {
    redirect = '/account/department/learningBooks/overview';
  } else {
    redirect = '/controlpanel/learningBooks/overview';
  }

  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/learningBooks/${id}`,
      data
    });

    if (res.status === 200) {
      showAlert('success', 'Het leerboek is succesvol gewijzigd');
      window.setTimeout(() => {
        location.assign(redirect);
      }, 1500);
    }
  } catch (err) {
    document.getElementById('updateLearningBook').disabled = false;
    document.getElementById('updateLearningBook').value = 'Pas aan';
    showAlert('error', err.response.data.message);
  }
};

export const deleteLearningBook = async id => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/learningBooks/${id}`
    });

    if (res.status === 204) {
      showAlert('success', 'Het leerboek is succesvol verwijderd');
      location.reload(true);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};
