/* eslint-disable */
import '@babel/polyfill';
import { login, logout } from './login';
import { showAlert } from './alerts';
import { createGame, deleteGame, updateGame } from './game';
import {
  createDidacticMaterial,
  deleteDidacticMaterial,
  updateDidacticMaterial
} from './didacticMaterial';
import {
  createLearningBook,
  deleteLearningBook,
  updateLearningBook
} from './learningBook';
import {
  createInformativeBook,
  deleteInformativeBook,
  updateInformativeBook
} from './informativeBook';

import {
  createReadingAndPictureBook,
  deleteReadingAndPictureBook,
  updateReadingAndPictureBook
} from './readingAndPictureBook';

import {
  createUser,
  deleteUser,
  updateUser,
  updateUserPassword,
  // updateUserSettings,
  sendResetEmail,
  resetUserPassword
} from './user';
import {
  createGameReservation,
  deleteGameReservation,
  updateGameReservation,
  createDidacticMaterialReservation,
  deleteDidacticMaterialReservation,
  createLearningBookReservation,
  deleteLearningBookReservation,
  createInformativeBookReservation,
  deleteInformativeBookReservation,
  createReadingAndPictureBookReservation,
  deleteReadingAndPictureBookReservation
} from './reservation';

import {
  createLocatieDropDown,
  createKindGesprekDropDown,
  createVakDropDown,
  createIsolatieDropDown,
  createLeerprofielDropDown,
  createRolDropDown,
  createDepartmentDropDown,
  createNiveauDropDown,
  createUitleenbaarDropDown,
  createHandleidingDropDown,
  createExecutieveFunctieDropDown
} from './dropdown.js';

import { createLinearSlider, createNonLinearSlider } from './slider.js';
import Cookies from 'js-cookie';

// ***** BUTTONS *****
const logInBtn = document.querySelector('.login-form');
const logoutBtn = document.getElementById('logout');
const passwordResetEmailBtn = document.getElementById('sendPasswordResetEmail');
const resetPasswordBtn = document.querySelector('.resetPassword');
const gameReservationBtn = document.getElementById('gameReservation');
const learningBookReservationBtn = document.getElementById(
  'learningBookReservation'
);
const informativeBookReservationBtn = document.getElementById(
  'informativeBookReservation'
);

const didacticMaterialReservationBtn = document.getElementById(
  'didacticMaterialReservation'
);

const readingAndPictureBookReservationBtn = document.getElementById(
  'readingAndPictureBookReservation'
);

const deleteReadingAndPictureBookReservationBtns = document.querySelectorAll(
  '#deleteReadingAndPictureBookReservation'
);
const deleteDidacticMaterialReservationBtns = document.querySelectorAll(
  '#deleteDidacticMaterialReservation'
);
const deleteGameReservationBtns = document.querySelectorAll(
  '#deleteGameReservation'
);
const deleteLearningBookReservationBtns = document.querySelectorAll(
  '#deleteLearningBookReservation'
);
const deleteInformativeBookReservationBtns = document.querySelectorAll(
  '#deleteInformativeBookReservation'
);
const updateGameReservationBtns = document.querySelectorAll(
  '#updateGameReservation'
);

const filterGamesBtn = document.getElementById('games-filter');
const filterDidacticMaterialBtn = document.getElementById(
  'didacticMaterial-filter'
);
const filterLearningBookBtn = document.getElementById('learningBook-filter');
const filterInformativeBookBtn = document.getElementById(
  'informativeBook-filter'
);

const filterVirtualRealityAppsBtn = document.getElementById('virtualRealityApps-filter');

const filterReadingAndPictureBookBtn = document.getElementById(
  'readingAndPictureBook-filter'
);

const resetFilterVirtualRealityAppsBtn = document.getElementById('reset-virtualRealityApps-filter');
const resetFilterGamesBtn = document.getElementById('reset-games-filter');
const resetFilterDidacticMaterialBtn = document.getElementById(
  'reset-didacticMaterial-filter'
);
const resetFilterLearningBookBtn = document.getElementById(
  'reset-learningBook-filter'
);
const resetFilterInformativeBookBtn = document.getElementById(
  'reset-informativeBook-filter'
);

const resetFilterReadingAndPictureBookBtn = document.getElementById(
  'reset-readingAndPictureBook-filter'
);

const createGameBtn = document.querySelector('.createGame');
const deleteGameBtns = document.querySelectorAll('.icon-close#game');
const updateGameBtn = document.querySelector('.updateGame');
const createDidacticMaterialBtn = document.querySelector(
  '.createDidacticMaterial'
);
const deleteDidacticMaterialBtns = document.querySelectorAll(
  '.icon-close#didacticMaterial'
);
const updateDidacticMaterialBtn = document.querySelector(
  '.updateDidacticMaterial'
);
const createLearningBookBtn = document.querySelector('.createLearningBook');
const updateLearningBookBtn = document.querySelector('.updateLearningBook');
const deleteLearningBookBtns = document.querySelectorAll(
  '.icon-close#learningBook'
);
const createInformativeBookBtn = document.querySelector(
  '.createInformativeBook'
);
const updateInformativeBookBtn = document.querySelector(
  '.updateInformativeBook'
);
const deleteInformativeBookBtns = document.querySelectorAll(
  '.icon-close#informativeBook'
);

const createUserBtn = document.querySelector('.createUser');
const deleteUserBtns = document.querySelectorAll('.icon-close#user');
const updateUserBtn = document.querySelector('.updateUser');
const createReadingAndPictureBookBtn = document.querySelector(
  '.createReadingAndPictureBook'
);
const updateReadingAndPictureBookBtn = document.querySelector(
  '.updateReadingAndPictureBook'
);
const deleteReadingAndPictureBookBtns = document.querySelectorAll(
  '.icon-close#readingAndPictureBook'
);

const updateAccountSettingsBtn = document.querySelector('.settingsAccountData');
const updateAccountPasswordBtn = document.querySelector(
  '.settingsAccountPassword'
);
const gameOverview = document.getElementById('gameOverview');
const didacticMaterialOverview = document.getElementById(
  'didacticMaterialOverview'
);
const informativeBookOverview = document.getElementById(
  'informativeBookOverview'
);
const readingAndPictureBookOverview = document.getElementById(
  'readingAndPictureBookOverview'
);

const learningBookOverview = document.getElementById('learningBookOverview');

// ***** SLIDERS *****
const leeftijdSlider = document.getElementById('leeftijdSlider');
const gameSpelersSlider = document.getElementById('spelersSlider');

// ***** DROPDOWN *****

const gameUpdateKindgesprekDropDown = document.getElementById(
  'updateKindgesprekDropDown'
);

const gameUpdateIsolatieDropDown = document.getElementById(
  'updateIsolatieDropDown'
);

const gameUpdateLocatieDropDown = document.getElementById(
  'updateLocatieDropDown'
);

const gameUpdateVakkenDropDown = document.getElementById(
  'updateVakkenDropDown'
);

const gameUpdateLeerprofielDropDown = document.getElementById(
  'updateLeerprofielDropDown'
);

const gameUpdateExecutieveFunctieDropDown = document.getElementById(
  'updateExecutieveFunctieDropDown'
);

const userUpdateRolDropDown = document.getElementById('updateRolDropDown');
const userUpdateDepartmentDropDown = document.getElementById(
  'updateDepartmentDropDown'
);
const userDepartmentDropDown = document.getElementById(
  'userDepartmentDropDown'
);

const locatieDropDown = document.getElementById('locatieDropDown');
const niveauDropDown = document.getElementById('niveauDropDown');
const vakDropDown = document.getElementById('vakkenDropDown');
const isolatieDropDown = document.getElementById('isolatieDropDown');
const uitleenbaarDropDown = document.getElementById('uitleenbaarDropDown');
const handleidingDropDown = document.getElementById('handleidingDropDown');
const kindgesprekDropDown = document.getElementById('kindgesprekDropDown');
const leerprofielDropDown = document.getElementById('leerprofielDropDown');
const executieveFunctieDropDown = document.getElementById('executieveFunctieDropDown');

// ***** ALGEMEEN *****
//TODO verzend een mail bij uitlening (bekijken of dit mogelijk is).
//TODO bekijk eerder uitgeleende artikelen

// ***** SLIDERS *****
if (leeftijdSlider) {
  createNonLinearSlider(leeftijdSlider, 1, 99, 18);
}

if (gameSpelersSlider) {
  createLinearSlider(gameSpelersSlider, 1, 10);
}

// ***** GENERAL BUTTONS *****
if (logInBtn) {
  logInBtn.addEventListener('submit', e => {
    e.preventDefault();

    const email = document.getElementById('email2').value;
    const password = document.getElementById('pass3').value;

    login(email, password);
  });
}

if (updateAccountPasswordBtn) {
  updateAccountPasswordBtn.addEventListener('submit', async e => {
    e.preventDefault();
    document.getElementById('settingsAccountPassword').value = 'Updating...';

    const passwordCurrent = document.getElementById('userPaswoord').value;
    const password = document.getElementById('userNieuwPaswoord').value;
    const passwordConfirm = document.getElementById('userNieuwPaswoordBevestig')
      .value;

    await updateUserPassword(passwordCurrent, password, passwordConfirm);
    document.getElementById('settingsAccountPassword').value =
      'Bewaar paswoord';
  });
}

if (passwordResetEmailBtn) {
  passwordResetEmailBtn.addEventListener('submit', e => {
    e.preventDefault();

    const email = document.getElementById('userEmail').value;
    sendResetEmail(email);
  });
}

if (resetPasswordBtn) {
  resetPasswordBtn.addEventListener('submit', e => {
    e.preventDefault();
    document.getElementById('resetPassword').value = 'Updating...';

    const form = new FormData();
    form.append('token', window.location.href.split('/')[5]);
    form.append('password', document.getElementById('userNieuwPaswoord').value);
    form.append(
      'passwordConfirm',
      document.getElementById('userNieuwPaswoordBevestig').value
    );

    if (form.get('password') === form.get('passwordConfirm')) {
      resetUserPassword(form);
    } else {
      showAlert('error', 'De ingevoerde paswoorden zijn niet identiek');
      document.getElementById('resetPassword').value = 'Bewaar paswoord';
    }
  });
}
// ***** OVERVIEW *****
// Remove every Cookie en set all values to default when the overview loads
if (gameOverview) {
  window.addEventListener('load', () => {
    const urlContainsSearchQuery =
      window.location.href.indexOf('/games/overview?') > -1;
    if (!urlContainsSearchQuery) {
      //Remove cookies
      Cookies.remove('filter-beschrijving');
      Cookies.remove('filter-location');
      Cookies.remove('filter-vakken');
      Cookies.remove('filter-leerprofiel');
      Cookies.remove('filter-leeftijd');
      Cookies.remove('filter-spelers');
      Cookies.remove('filter-executieveFuncties')

      //Set values to default
      document.getElementById('games-description-search').value = '';
      document.getElementById('games-location-search').selectedIndex = 0;
      document.getElementById('games-courses-search').selectedIndex = 0;
      document.getElementById('games-leerprofiel-search').selectedIndex = 0;
      document.getElementById('games-executieve-functies-search').selectedIndex = 0;
      leeftijdSlider.noUiSlider.reset();
      gameSpelersSlider.noUiSlider.reset();
    }
  });
}

if (didacticMaterialOverview) {
  window.addEventListener('load', () => {
    const urlContainsSearchQuery =
      window.location.href.indexOf('/didacticMaterials/overview?') > -1;
    if (!urlContainsSearchQuery) {
      //Remove cookies
      Cookies.remove('filter-beschrijving-didactischMateriaal');
      Cookies.remove('filter-locatie-didactischMateriaal');
      Cookies.remove('filter-niveau-didactischMateriaal');
      Cookies.remove('filter-vak-didactischMateriaal');

      //Set values to default
      document.getElementById('didacticMaterial-description-search').value = '';
      document.getElementById(
        'didacticMaterial-grade-search'
      ).selectedIndex = 0;
      document.getElementById(
        'didacticMaterial-location-search'
      ).selectedIndex = 0;
      document.getElementById(
        'didacticMaterial-courses-search'
      ).selectedIndex = 0;
    }
  });
}

if (informativeBookOverview) {
  window.addEventListener('load', () => {
    const urlContainsSearchQuery =
      window.location.href.indexOf('/informativeBooks/overview?') > -1;
    if (!urlContainsSearchQuery) {
      //Remove cookies
      Cookies.remove('filter-beschrijving-informatiefBoek');
      Cookies.remove('filter-locatie-informatiefBoek');
      Cookies.remove('filter-leerprofiel-informatiefBoek');

      //Set values to default
      document.getElementById('informativeBook-description-search').value = '';
      document.getElementById(
        'informativeBook-location-search'
      ).selectedIndex = 0;
      document.getElementById(
        'informativeBook-leerprofiel-search'
      ).selectedIndex = 0;
    }
  });
}

if (readingAndPictureBookOverview) {
  window.addEventListener('load', () => {
    const urlContainsSearchQuery =
      window.location.href.indexOf('/readingAndPictureBooks/overview?') > -1;
    if (!urlContainsSearchQuery) {
      //Remove cookies
      Cookies.remove('filter-beschrijving-readingAndPictureBook');
      Cookies.remove('filter-locatie-readingAndPictureBook');
      Cookies.remove('filter-leeftijd-readingAndPictureBook');

      //Set values to default
      document.getElementById(
        'readingAndPictureBook-description-search'
      ).value = '';
      document.getElementById(
        'readingAndPictureBook-location-search'
      ).selectedIndex = 0;
      leeftijdSlider.noUiSlider.reset();
    }
  });
}

if (learningBookOverview) {
  window.addEventListener('load', () => {
    const urlContainsSearchQuery =
      window.location.href.indexOf('/learningBooks/overview?') > -1;
    if (!urlContainsSearchQuery) {
      //Remove cookies
      Cookies.remove('filter-beschrijving-leerBoek');
      Cookies.remove('filter-locatie-leerBoek');
      Cookies.remove('filter-vak-leerBoek');
      Cookies.remove('filter-niveau-leerBoek');

      //Set values to default
      document.getElementById('learningBook-description-search').value = '';
      document.getElementById('learningBook-location-search').selectedIndex = 0;
      document.getElementById('learningBook-courses-search').selectedIndex = 0;
      document.getElementById('learningBook-grade-search').selectedIndex = 0;
    }
  });
}
// ***** GAME BUTTONS *****
if (createGameBtn) {
  let gameKindgesprekDropDownValue = createKindGesprekDropDown(
    kindgesprekDropDown
  );

  let gameIsolatieDropDownValue = createIsolatieDropDown(isolatieDropDown);

  let locatieDropDownValue = createLocatieDropDown(locatieDropDown);

  let uitleenbaarDropDownValue = createUitleenbaarDropDown(uitleenbaarDropDown);


  let gameVakkenDropDownValue = createVakDropDown(
    vakDropDown,
    'undefined',
    true
  );

  let gameLeerprofielDropDownValue = createLeerprofielDropDown(
    leerprofielDropDown
  );

  let gameExecutieveFunctieDropDownValue = createExecutieveFunctieDropDown(executieveFunctieDropDown);

  createGameBtn.addEventListener('submit', e => {
    e.preventDefault();
    const errorMessages = [];

    const form = new FormData();
    if (document.getElementById('gameNaam').value != '') {
      form.append('naam', document.getElementById('gameNaam').value);
    } else {
      errorMessages.push('Er dient een naam te worden opgegeven.');
    }

    if (
      locatieDropDownValue.value() === '' ||
      locatieDropDownValue.value() === undefined ||
      locatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Er dient een locatie te worden opgegeven.');
    } else {
      form.append('locatie', locatieDropDownValue.value());
    }

    if (
      gameIsolatieDropDownValue.value() === '' ||
      gameIsolatieDropDownValue.value() === undefined ||
      gameIsolatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Isolatie dient te worden opgegeven.');
    } else {
      form.append('isolatie', gameIsolatieDropDownValue.value());
    }

    if (
      gameExecutieveFunctieDropDownValue.value() === '' ||
      gameExecutieveFunctieDropDownValue.value() === undefined ||
      gameExecutieveFunctieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Executieve functie dient te worden opgegeven.');
    } else {
      form.append('executieveFunctie', gameExecutieveFunctieDropDownValue.value());
    }

    if (
      gameKindgesprekDropDownValue.value() == undefined ||
      gameKindgesprekDropDownValue.value().length == 0
    ) {
      form.append('kindgesprek', '');
    } else {
      gameKindgesprekDropDownValue.value().forEach((kindgesprek, index) => {
        form.append('kindgesprek[' + index + ']', kindgesprek);
      });
    }

    if (
      gameLeerprofielDropDownValue.value() == undefined ||
      gameLeerprofielDropDownValue.value().length == 0
    ) {
      form.append('leerprofiel', '');
    } else {
      gameLeerprofielDropDownValue.value().forEach((leerprofiel, index) => {
        form.append('leerprofiel[' + index + ']', leerprofiel);
      });
    }

    if (
      gameVakkenDropDownValue.value() == undefined ||
      gameVakkenDropDownValue.value().length == 0
    ) {
      form.append('vak', '');
    } else {
      gameVakkenDropDownValue.value().forEach((vak, index) => {
        form.append('vak[' + index + ']', vak);
      });
    }

    form.append(
      'beschrijving',
      document.getElementById('gameBeschrijving').value
    );

    form.append(
      'extraInformatie',
      document.getElementById('gameExtraInformatie').value
    );
    if (uitleenbaarDropDownValue.value() != undefined) {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    form.append('minLeeftijd', leeftijdSlider.noUiSlider.get()[0]);
    form.append('maxLeeftijd', leeftijdSlider.noUiSlider.get()[1]);
    form.append('minSpelers', gameSpelersSlider.noUiSlider.get()[0]);
    form.append('maxSpelers', gameSpelersSlider.noUiSlider.get()[1]);
    if (document.getElementById('gameAfbeelding').files.length == 1) {
      form.append(
        'afbeelding',
        document.getElementById('gameAfbeelding').files[0]
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('createGame').value = 'Voeg toe';
      document.getElementById('createGame').disabled = false;
    } else {
      document.getElementById('createGame').value = 'Updating...';
      document.getElementById('createGame').disabled = true;
      createGame(form);
    }
  });
}

if (updateGameBtn) {
  // Initialise the dropdowns and sliders
  const role = document.getElementById('role').value;
  let gameUpdateUitleenbaarDropDown;
  let gameUpdateLocatieDropDownValue;
  let gameUpdateKindgesprekDropDownValue;
  let gameUpdateVakkenDropDownValue;
  let gameUpdateLeerprofielDropDownValue;
  let gameUpdateExecutieveFunctieDropDownValue;
  let gameUpdateIsolatieDropDownValue;

  if (role === 'admin') {
    const gameLocatie = gameUpdateLocatieDropDown.dataset.gameLocatie;
    gameUpdateLocatieDropDownValue = createLocatieDropDown(
      gameUpdateLocatieDropDown,
      gameLocatie
    );

    const gameKindgesprek =
      gameUpdateKindgesprekDropDown.dataset.gameKindgesprek;
    gameUpdateKindgesprekDropDownValue = createKindGesprekDropDown(
      gameUpdateKindgesprekDropDown,
      gameKindgesprek
    );

    const gameVak = gameUpdateVakkenDropDown.dataset.gameVak;
    gameUpdateVakkenDropDownValue = createVakDropDown(
      gameUpdateVakkenDropDown,
      gameVak,
      true
    );

    const gameLeerprofiel =
      gameUpdateLeerprofielDropDown.dataset.gameLeerprofiel;
    gameUpdateLeerprofielDropDownValue = createLeerprofielDropDown(
      gameUpdateLeerprofielDropDown,
      gameLeerprofiel
    );

    const gameExecutieveFunctie =
      gameUpdateExecutieveFunctieDropDown.dataset.gameExecutievefunctie;

    gameUpdateExecutieveFunctieDropDownValue = createExecutieveFunctieDropDown(
      gameUpdateExecutieveFunctieDropDown,
      gameExecutieveFunctie
    )

    const gameIsolatie = gameUpdateIsolatieDropDown.dataset.gameIsolatie;
    gameUpdateIsolatieDropDownValue = createIsolatieDropDown(
      gameUpdateIsolatieDropDown,
      gameIsolatie
    );

    const gameUitleenbaar = uitleenbaarDropDown.dataset.gameUitleenbaar;
    gameUpdateUitleenbaarDropDown = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      gameUitleenbaar
    );

    const minSpelers = gameSpelersSlider.dataset.gameMinspelers;
    const maxSpelers = gameSpelersSlider.dataset.gameMaxspelers;
    gameSpelersSlider.noUiSlider.set([minSpelers, maxSpelers]);

    const minLeeftijd = leeftijdSlider.dataset.gameMinleeftijd;
    const maxLeeftijd = leeftijdSlider.dataset.gameMaxleeftijd;
    leeftijdSlider.noUiSlider.set([minLeeftijd, maxLeeftijd]);
  }

  if (role === 'user') {
    const gameUitleenbaar = uitleenbaarDropDown.dataset.gameUitleenbaar;
    gameUpdateUitleenbaarDropDown = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      gameUitleenbaar
    );
  }

  // Eventhandler for updating
  updateGameBtn.addEventListener('submit', e => {
    e.preventDefault();

    const errorMessages = [];

    const form = new FormData();
    form.append('_id', document.getElementById('id').value);
    form.append('role', role);

    if (role === 'admin') {
      if (document.getElementById('gameNaam').value != '') {
        form.append('naam', document.getElementById('gameNaam').value);
      } else {
        errorMessages.push('Er dient een naam te worden opgegeven.');
      }

      if (gameUpdateLocatieDropDownValue.value() != '') {
        form.append('locatie', gameUpdateLocatieDropDownValue.value());
      } else {
        errorMessages.push('Er dient een locatie te worden opgegeven.');
      }
      if (gameUpdateIsolatieDropDownValue != '') {
        form.append('isolatie', gameUpdateIsolatieDropDownValue.value());
      } else {
        errorMessages.push('Isolatie dient te worden opgegeven.');
      }

      if (
        gameUpdateKindgesprekDropDownValue.value() == undefined ||
        gameUpdateKindgesprekDropDownValue.value().length == 0
      ) {
        form.append('kindgesprek', '');
      } else {
        gameUpdateKindgesprekDropDownValue
          .value()
          .forEach((kindgesprek, index) => {
            form.append('kindgesprek[' + index + ']', kindgesprek);
          });
      }

      if (
        gameUpdateLeerprofielDropDownValue.value() == undefined ||
        gameUpdateLeerprofielDropDownValue.value().length == 0
      ) {
        form.append('leerprofiel', '');
      } else {
        gameUpdateLeerprofielDropDownValue
          .value()
          .forEach((leerprofiel, index) => {
            form.append('leerprofiel[' + index + ']', leerprofiel);
          });
      }

      if (
        gameUpdateExecutieveFunctieDropDownValue.value() == undefined ||
        gameUpdateExecutieveFunctieDropDownValue.value().length == 0
      ) {
        form.append('executieveFunctie', '');
      } else {
        gameUpdateExecutieveFunctieDropDownValue
          .value()
          .forEach((executieveFunctie, index) => {
            form.append('executieveFunctie[' + index + ']', executieveFunctie);
          });
      }

      if (
        gameUpdateVakkenDropDownValue.value() == undefined ||
        gameUpdateVakkenDropDownValue.value().length == 0
      ) {
        form.append('vak', '');
      } else {
        gameUpdateVakkenDropDownValue.value().forEach((vak, index) => {
          form.append('vak[' + index + ']', vak);
        });
      }

      form.append(
        'beschrijving',
        document.getElementById('gameBeschrijving').value
      );

      form.append('uitleenbaar', gameUpdateUitleenbaarDropDown.value());
      form.append('minLeeftijd', leeftijdSlider.noUiSlider.get()[0]);
      form.append('maxLeeftijd', leeftijdSlider.noUiSlider.get()[1]);
      form.append('minSpelers', gameSpelersSlider.noUiSlider.get()[0]);
      form.append('maxSpelers', gameSpelersSlider.noUiSlider.get()[1]);
      if (document.getElementById('gameAfbeelding').files.length == 1) {
        form.append(
          'afbeelding',
          document.getElementById('gameAfbeelding').files[0]
        );
      }
    }

    if (role === 'user') {
      form.append('uitleenbaar', gameUpdateUitleenbaarDropDown.value());
    }

    if (role === 'user' || role === 'admin') {
      form.append(
        'extraInformatie',
        document.getElementById('gameExtraInformatie').value
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('updateGame').value = 'Pas aan';
      document.getElementById('updateGame').disabled = false;
    } else {
      document.getElementById('updateGame').disabled = true;
      document.getElementById('updateGame').value = 'Updating...';
      updateGame(form);
    }
  });
}

deleteGameBtns.forEach(deleteGameBtn => {
  if (deleteGameBtn) {
    deleteGameBtn.addEventListener('click', e => {
      const { gameId } = e.target.dataset;
      const choice = confirm(e.target.dataset.confirm);

      if (choice) {
        deleteGame(gameId);
      }
    });
  }
});

// ***** GAMERESERVATIONS BUTTONS *****
if (gameReservationBtn) {
  gameReservationBtn.addEventListener('click', e => {
    const { gameId, userId } = e.target.dataset;
    createGameReservation(userId, gameId);
  });
}

deleteGameReservationBtns.forEach(deleteGameReservationBtn => {
  if (deleteGameReservationBtn) {
    deleteGameReservationBtn.addEventListener('click', e => {
      const { gamebookingId, gameId } = e.currentTarget.dataset;

      deleteGameReservation(gamebookingId, gameId);
    });
  }
});

updateGameReservationBtns.forEach(updateGameReservationBtn => {
  if (updateGameReservationBtn) {
    updateGameReservationBtn.addEventListener('click', e => {
      const { gamebookingId } = e.currentTarget.dataset;
      updateGameReservation(gamebookingId);
    });
  }
});

// ***** DIDACTICMATERIAL BUTTONS *****

if (createDidacticMaterialBtn) {
  let locatieDropDownValue = createLocatieDropDown(locatieDropDown);

  let vakkenDropDownValue = createVakDropDown(vakDropDown, 'undefined', true);

  let niveauDropDownValue = createNiveauDropDown(
    niveauDropDown,
    'undefined',
    true
  );

  let uitleenbaarDropDownValue = createUitleenbaarDropDown(uitleenbaarDropDown);

  createDidacticMaterialBtn.addEventListener('submit', e => {
    e.preventDefault();
    const errorMessages = [];

    const form = new FormData();

    if (document.getElementById('didacticMaterialNaam').value != '') {
      form.append(
        'naam',
        document.getElementById('didacticMaterialNaam').value
      );
    } else {
      errorMessages.push('Er dient een naam te worden opgegeven.');
    }

    if (
      locatieDropDownValue.value() === '' ||
      locatieDropDownValue.value() === undefined ||
      locatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Er dient een locatie te worden opgegeven.');
    } else {
      form.append('locatie', locatieDropDownValue.value());
    }

    if (
      vakkenDropDownValue.value() == undefined ||
      vakkenDropDownValue.value().length == 0
    ) {
      form.append('vak', '');
    } else {
      vakkenDropDownValue.value().forEach((vak, index) => {
        form.append('vak[' + index + ']', vak);
      });
    }

    if (
      niveauDropDownValue.value() == undefined ||
      niveauDropDownValue.value().length == 0
    ) {
      form.append('niveau', '');
    } else {
      niveauDropDownValue.value().forEach((niveau, index) => {
        form.append('niveau[' + index + ']', niveau);
      });
    }

    form.append(
      'beschrijving',
      document.getElementById('didacticMaterialBeschrijving').value
    );

    form.append(
      'extraInformatie',
      document.getElementById('didacticMaterialExtraInformatie').value
    );

    if (uitleenbaarDropDownValue.value() != undefined) {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }
    if (
      document.getElementById('didacticMaterialAfbeelding').files.length == 1
    ) {
      form.append(
        'afbeelding',
        document.getElementById('didacticMaterialAfbeelding').files[0]
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('createDidacticMaterial').value = 'Voeg toe';
      document.getElementById('createDidacticMaterial').disabled = false;
    } else {
      document.getElementById('createDidacticMaterial').value = 'Updating...';
      document.getElementById('createDidacticMaterial').disabled = true;
      createDidacticMaterial(form);
    }
  });
}

if (updateDidacticMaterialBtn) {
  // Initialise the dropdowns and sliders
  const role = document.getElementById('role').value;
  let uitleenbaarDropDownValue;
  let niveauDropDownValue;
  let vakkenDropDownValue;
  let locatieDropDownValue;

  if (role === 'admin') {
    const locatie = locatieDropDown.dataset.didactischmateriaalLocatie;
    locatieDropDownValue = createLocatieDropDown(locatieDropDown, locatie);

    const vak = vakDropDown.dataset.didactischmateriaalVak;
    vakkenDropDownValue = createVakDropDown(vakDropDown, vak, true);

    const niveau = niveauDropDown.dataset.didactischmateriaalNiveau;
    niveauDropDownValue = createNiveauDropDown(niveauDropDown, niveau, true);

    const uitleenbaar =
      uitleenbaarDropDown.dataset.didactischmateriaalUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );
  }

  if (role === 'user') {
    const uitleenbaar =
      uitleenbaarDropDown.dataset.didactischmateriaalUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );
  }

  updateDidacticMaterialBtn.addEventListener('submit', e => {
    e.preventDefault();

    const errorMessages = [];

    const form = new FormData();
    form.append('_id', document.getElementById('id').value);
    form.append('role', role);

    if (role === 'admin') {
      if (document.getElementById('didacticMaterialNaam').value != '') {
        form.append(
          'naam',
          document.getElementById('didacticMaterialNaam').value
        );
      } else {
        errorMessages.push('Er dient een naam te worden opgegeven.');
      }

      if (locatieDropDownValue.value() != '') {
        form.append('locatie', locatieDropDownValue.value());
      } else {
        errorMessages.push('Er dient een locatie te worden opgegeven.');
      }

      if (
        vakkenDropDownValue.value() == undefined ||
        vakkenDropDownValue.value().length == 0
      ) {
        form.append('vak', '');
      } else {
        vakkenDropDownValue.value().forEach((vak, index) => {
          form.append('vak[' + index + ']', vak);
        });
      }

      if (
        niveauDropDownValue.value() == undefined ||
        niveauDropDownValue.value().length == 0
      ) {
        form.append('niveau', '');
      } else {
        niveauDropDownValue.value().forEach((niveau, index) => {
          form.append('niveau[' + index + ']', niveau);
        });
      }

      form.append(
        'beschrijving',
        document.getElementById('didacticMaterialBeschrijving').value
      );

      if (
        document.getElementById('didacticMaterialAfbeelding').files.length == 1
      ) {
        form.append(
          'afbeelding',
          document.getElementById('didacticMaterialAfbeelding').files[0]
        );
      }
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (role === 'user') {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (role === 'user' || role === 'admin') {
      form.append(
        'extraInformatie',
        document.getElementById('didacticMaterialExtraInformatie').value
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('updateDidacticMaterial').value = 'Pas aan';
      document.getElementById('updateDidacticMaterial').disabled = false;
    } else {
      document.getElementById('updateDidacticMaterial').value = 'Updating...';
      document.getElementById('updateDidacticMaterial').disabled = true;
      updateDidacticMaterial(form);
    }
  });
}

deleteDidacticMaterialBtns.forEach(deleteDidacticMaterialBtn => {
  if (deleteDidacticMaterialBtn) {
    deleteDidacticMaterialBtn.addEventListener('click', e => {
      const { didacticmaterialId } = e.target.dataset;
      const choice = confirm(e.target.dataset.confirm);

      if (choice) {
        deleteDidacticMaterial(didacticmaterialId);
      }
    });
  }
});

// ***** DIDACTICMATERIALRESERVATION BUTTONS *****
if (didacticMaterialReservationBtn) {
  didacticMaterialReservationBtn.addEventListener('click', e => {
    const { didacticmaterialId, userId } = e.target.dataset;
    createDidacticMaterialReservation(userId, didacticmaterialId);
  });
}

deleteDidacticMaterialReservationBtns.forEach(
  deleteDidacticMaterialReservationBtn => {
    if (deleteDidacticMaterialReservationBtn) {
      deleteDidacticMaterialReservationBtn.addEventListener('click', e => {
        const {
          didacticmaterialbookingId,
          didacticmaterialId
        } = e.currentTarget.dataset;

        deleteDidacticMaterialReservation(
          didacticmaterialbookingId,
          didacticmaterialId
        );
      });
    }
  }
);
// ***** LEARNINGBOOK BUTTONS *****
if (createLearningBookBtn) {
  let locatieDropDownValue = createLocatieDropDown(locatieDropDown);

  let vakkenDropDownValue = createVakDropDown(vakDropDown, 'undefined', true);

  let niveauDropDownValue = createNiveauDropDown(
    niveauDropDown,
    'undefined',
    true
  );

  let uitleenbaarDropDownValue = createUitleenbaarDropDown(uitleenbaarDropDown);

  let handleidingDropDownValue = createHandleidingDropDown(handleidingDropDown);

  createLearningBookBtn.addEventListener('submit', e => {
    e.preventDefault();
    const errorMessages = [];

    const form = new FormData();

    if (document.getElementById('learningBookNaam').value != '') {
      form.append('naam', document.getElementById('learningBookNaam').value);
    } else {
      errorMessages.push('Er dient een naam te worden opgegeven.');
    }

    if (
      locatieDropDownValue.value() === '' ||
      locatieDropDownValue.value() === undefined ||
      locatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Er dient een locatie te worden opgegeven.');
    } else {
      form.append('locatie', locatieDropDownValue.value());
    }

    if (
      vakkenDropDownValue.value() == undefined ||
      vakkenDropDownValue.value().length == 0
    ) {
      form.append('vak', '');
    } else {
      vakkenDropDownValue.value().forEach((vak, index) => {
        form.append('vak[' + index + ']', vak);
      });
    }

    if (
      niveauDropDownValue.value() == undefined ||
      niveauDropDownValue.value().length == 0
    ) {
      form.append('niveau', '');
    } else {
      niveauDropDownValue.value().forEach((niveau, index) => {
        form.append('niveau[' + index + ']', niveau);
      });
    }

    if (handleidingDropDownValue.value() != undefined) {
      form.append('handleiding', handleidingDropDownValue.value());
    } else {
      errorMessages.push(
        'Er dient te worden opgegeven of er een handleiding aanwezig is.'
      );
    }

    form.append(
      'beschrijving',
      document.getElementById('learningBookBeschrijving').value
    );

    form.append(
      'extraInformatie',
      document.getElementById('learningBookExtraInformatie').value
    );

    form.append(
      'jaarUitgave',
      document.getElementById('learningBookJaarUitgave').value
    );

    if (uitleenbaarDropDownValue.value() != undefined) {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }
    if (document.getElementById('learningBookAfbeelding').files.length == 1) {
      form.append(
        'afbeelding',
        document.getElementById('learningBookAfbeelding').files[0]
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('createLearningBook').value = 'Voeg toe';
      document.getElementById('createLearningBook').disabled = false;
    } else {
      document.getElementById('createLearningBook').value = 'Updating...';
      document.getElementById('createLearningBook').disabled = true;
      createLearningBook(form);
    }
  });
}

if (updateLearningBookBtn) {
  // Initialise the dropdowns and sliders
  const role = document.getElementById('role').value;
  let uitleenbaarDropDownValue;
  let niveauDropDownValue;
  let vakkenDropDownValue;
  let locatieDropDownValue;
  let handleidingDropDownValue;

  if (role === 'admin') {
    const locatie = locatieDropDown.dataset.leerboekLocatie;
    locatieDropDownValue = createLocatieDropDown(locatieDropDown, locatie);

    const vak = vakDropDown.dataset.leerboekVak;
    vakkenDropDownValue = createVakDropDown(vakDropDown, vak, true);

    const niveau = niveauDropDown.dataset.leerboekNiveau;
    niveauDropDownValue = createNiveauDropDown(niveauDropDown, niveau, true);

    const uitleenbaar = uitleenbaarDropDown.dataset.leerboekUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );

    const handleiding = handleidingDropDown.dataset.leerboekHandleiding;
    handleidingDropDownValue = createHandleidingDropDown(
      handleidingDropDown,
      handleiding
    );
  }

  if (role === 'user') {
    const uitleenbaar = uitleenbaarDropDown.dataset.leerboekUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );
  }

  updateLearningBookBtn.addEventListener('submit', e => {
    e.preventDefault();

    const errorMessages = [];

    const form = new FormData();
    form.append('_id', document.getElementById('id').value);
    form.append('role', role);

    if (role === 'admin') {
      if (document.getElementById('learningBookNaam').value != '') {
        form.append('naam', document.getElementById('learningBookNaam').value);
      } else {
        errorMessages.push('Er dient een naam te worden opgegeven.');
      }

      if (locatieDropDownValue.value() != '') {
        form.append('locatie', locatieDropDownValue.value());
      } else {
        errorMessages.push('Er dient een locatie te worden opgegeven.');
      }

      if (
        vakkenDropDownValue.value() == undefined ||
        vakkenDropDownValue.value().length == 0
      ) {
        form.append('vak', '');
      } else {
        vakkenDropDownValue.value().forEach((vak, index) => {
          form.append('vak[' + index + ']', vak);
        });
      }

      if (
        niveauDropDownValue.value() == undefined ||
        niveauDropDownValue.value().length == 0
      ) {
        form.append('niveau', '');
      } else {
        niveauDropDownValue.value().forEach((niveau, index) => {
          form.append('niveau[' + index + ']', niveau);
        });
      }

      form.append(
        'beschrijving',
        document.getElementById('learningBookBeschrijving').value
      );

      form.append(
        'jaarUitgave',
        document.getElementById('learningBookJaarUitgave').value
      );

      if (document.getElementById('learningBookAfbeelding').files.length == 1) {
        form.append(
          'afbeelding',
          document.getElementById('learningBookAfbeelding').files[0]
        );
      }
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
      form.append('handleiding', handleidingDropDownValue.value());
    }

    if (role === 'user') {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (role === 'user' || role === 'admin') {
      form.append(
        'extraInformatie',
        document.getElementById('learningBookExtraInformatie').value
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('updateLearningBook').value = 'Pas aan';
      document.getElementById('updateLearningBook').disabled = false;
    } else {
      document.getElementById('updateLearningBook').value = 'Updating...';
      document.getElementById('updateLearningBook').disabled = true;
      updateLearningBook(form);
    }
  });
}

deleteLearningBookBtns.forEach(deleteLearningBookBtn => {
  if (deleteLearningBookBtn) {
    deleteLearningBookBtn.addEventListener('click', e => {
      const { learningbookId } = e.target.dataset;
      const choice = confirm(e.target.dataset.confirm);

      if (choice) {
        deleteLearningBook(learningbookId);
      }
    });
  }
});

// ***** LEARNINGBOOKRESERVATION BUTTONS *****
if (learningBookReservationBtn) {
  learningBookReservationBtn.addEventListener('click', e => {
    const { learningbookId, userId } = e.target.dataset;
    createLearningBookReservation(userId, learningbookId);
  });
}

deleteLearningBookReservationBtns.forEach(deleteLearningBookReservationBtn => {
  if (deleteLearningBookReservationBtn) {
    deleteLearningBookReservationBtn.addEventListener('click', e => {
      const { learningbookbookingId, learningbookId } = e.currentTarget.dataset;

      deleteLearningBookReservation(learningbookbookingId, learningbookId);
    });
  }
});
// ***** READINGANDPICTUREBOOK BUTTONS *****
if (createReadingAndPictureBookBtn) {
  let isolatieDropDownValue = createIsolatieDropDown(isolatieDropDown);

  let locatieDropDownValue = createLocatieDropDown(locatieDropDown);

  let uitleenbaarDropDownValue = createUitleenbaarDropDown(uitleenbaarDropDown);

  createReadingAndPictureBookBtn.addEventListener('submit', e => {
    e.preventDefault();
    const errorMessages = [];

    const form = new FormData();
    if (document.getElementById('readingAndPictureBookNaam').value != '') {
      form.append(
        'naam',
        document.getElementById('readingAndPictureBookNaam').value
      );
    } else {
      errorMessages.push('Er dient een naam te worden opgegeven.');
    }

    if (
      locatieDropDownValue.value() === '' ||
      locatieDropDownValue.value() === undefined ||
      locatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Er dient een locatie te worden opgegeven.');
    } else {
      form.append('locatie', locatieDropDownValue.value());
    }

    if (
      isolatieDropDownValue.value() === '' ||
      isolatieDropDownValue.value() === undefined ||
      isolatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Isolatie dient te worden opgegeven.');
    } else {
      form.append('isolatie', isolatieDropDownValue.value());
    }

    form.append(
      'beschrijving',
      document.getElementById('readingAndPictureBookBeschrijving').value
    );

    form.append(
      'extraInformatie',
      document.getElementById('readingAndPictureBookExtraInformatie').value
    );

    if (uitleenbaarDropDownValue.value() != undefined) {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    form.append('minLeeftijd', leeftijdSlider.noUiSlider.get()[0]);
    form.append('maxLeeftijd', leeftijdSlider.noUiSlider.get()[1]);
    if (
      document.getElementById('readingAndPictureBookAfbeelding').files.length ==
      1
    ) {
      form.append(
        'afbeelding',
        document.getElementById('readingAndPictureBookAfbeelding').files[0]
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('createReadingAndPictureBook').value = 'Voeg toe';
      document.getElementById('createReadingAndPictureBook').disabled = false;
    } else {
      document.getElementById('createReadingAndPictureBook').value =
        'Updating...';
      document.getElementById('createReadingAndPictureBook').disabled = true;
      createReadingAndPictureBook(form);
    }
  });
}
if (updateReadingAndPictureBookBtn) {
  // Initialise the dropdowns and sliders
  const role = document.getElementById('role').value;
  let uitleenbaarDropDownValue;
  let locatieDropDownValue;
  let isolatieDropDownValue;

  if (role === 'admin') {
    const locatie = locatieDropDown.dataset.leesenprentenboekLocatie;
    locatieDropDownValue = createLocatieDropDown(locatieDropDown, locatie);

    const isolatie = isolatieDropDown.dataset.leesenprentenboekIsolatie;
    isolatieDropDownValue = createIsolatieDropDown(isolatieDropDown, isolatie);

    const uitleenbaar =
      uitleenbaarDropDown.dataset.leesenprentenboekUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );

    const minLeeftijd = leeftijdSlider.dataset.leesenprentenboekMinleeftijd;
    const maxLeeftijd = leeftijdSlider.dataset.leesenprentenboekMaxleeftijd;
    leeftijdSlider.noUiSlider.set([minLeeftijd, maxLeeftijd]);
  }

  if (role === 'user') {
    const uitleenbaar =
      uitleenbaarDropDown.dataset.leesenprentenboekUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );
  }

  // Eventhandler for updating
  updateReadingAndPictureBookBtn.addEventListener('submit', e => {
    e.preventDefault();

    const errorMessages = [];

    const form = new FormData();
    form.append('_id', document.getElementById('id').value);
    form.append('role', role);

    if (role === 'admin') {
      if (document.getElementById('readingAndPictureBookNaam').value != '') {
        form.append(
          'naam',
          document.getElementById('readingAndPictureBookNaam').value
        );
      } else {
        errorMessages.push('Er dient een naam te worden opgegeven.');
      }

      if (locatieDropDownValue.value() != '') {
        form.append('locatie', locatieDropDownValue.value());
      } else {
        errorMessages.push('Er dient een locatie te worden opgegeven.');
      }
      if (isolatieDropDownValue != '') {
        form.append('isolatie', isolatieDropDownValue.value());
      } else {
        errorMessages.push('Isolatie dient te worden opgegeven.');
      }

      form.append(
        'beschrijving',
        document.getElementById('readingAndPictureBookBeschrijving').value
      );
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
      form.append('minLeeftijd', leeftijdSlider.noUiSlider.get()[0]);
      form.append('maxLeeftijd', leeftijdSlider.noUiSlider.get()[1]);
      if (
        document.getElementById('readingAndPictureBookAfbeelding').files
          .length == 1
      ) {
        form.append(
          'afbeelding',
          document.getElementById('readingAndPictureBookAfbeelding').files[0]
        );
      }
    }

    if (role === 'user') {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (role === 'user' || role === 'admin') {
      form.append(
        'extraInformatie',
        document.getElementById('readingAndPictureBookExtraInformatie').value
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('updateReadingAndPictureBook').value = 'Pas aan';
      document.getElementById('updateReadingAndPictureBook').disabled = false;
    } else {
      document.getElementById('updateReadingAndPictureBook').value =
        'Updating...';
      document.getElementById('updateReadingAndPictureBook').disabled = true;
      updateReadingAndPictureBook(form);
    }
  });
}

deleteReadingAndPictureBookBtns.forEach(deleteReadingAndPictureBookBtn => {
  if (deleteReadingAndPictureBookBtn) {
    deleteReadingAndPictureBookBtn.addEventListener('click', e => {
      const { readingandpicturebookId } = e.target.dataset;
      const choice = confirm(e.target.dataset.confirm);

      if (choice) {
        deleteReadingAndPictureBook(readingandpicturebookId);
      }
    });
  }
});

// ***** READINGANDPICTUREBOOKRESERVATION BUTTONS *****
if (readingAndPictureBookReservationBtn) {
  readingAndPictureBookReservationBtn.addEventListener('click', e => {
    const { readingandpicturebookId, userId } = e.target.dataset;
    createReadingAndPictureBookReservation(userId, readingandpicturebookId);
  });
}

deleteReadingAndPictureBookReservationBtns.forEach(
  deleteReadingAndPictureBookReservationBtn => {
    if (deleteReadingAndPictureBookReservationBtn) {
      deleteReadingAndPictureBookReservationBtn.addEventListener('click', e => {
        const {
          readingandpicturebookbookingId,
          readingandpicturebookId
        } = e.currentTarget.dataset;

        deleteReadingAndPictureBookReservation(
          readingandpicturebookbookingId,
          readingandpicturebookId
        );
      });
    }
  }
);
// ***** INFORMATIVEBOOK BUTTONS *****
if (createInformativeBookBtn) {
  let kindgesprekDropDownValue = createKindGesprekDropDown(kindgesprekDropDown);

  let locatieDropDownValue = createLocatieDropDown(locatieDropDown);

  let uitleenbaarDropDownValue = createUitleenbaarDropDown(uitleenbaarDropDown);

  let leerprofielDropDownValue = createLeerprofielDropDown(leerprofielDropDown);

  createInformativeBookBtn.addEventListener('submit', e => {
    e.preventDefault();
    const errorMessages = [];

    const form = new FormData();
    if (document.getElementById('informativeBookNaam').value != '') {
      form.append('naam', document.getElementById('informativeBookNaam').value);
    } else {
      errorMessages.push('Er dient een naam te worden opgegeven.');
    }

    if (
      locatieDropDownValue.value() === '' ||
      locatieDropDownValue.value() === undefined ||
      locatieDropDownValue.value() === 'undefined'
    ) {
      errorMessages.push('Er dient een locatie te worden opgegeven.');
    } else {
      form.append('locatie', locatieDropDownValue.value());
    }

    if (
      kindgesprekDropDownValue.value() == undefined ||
      kindgesprekDropDownValue.value().length == 0
    ) {
      form.append('kindgesprek', '');
    } else {
      kindgesprekDropDownValue.value().forEach((kindgesprek, index) => {
        form.append('kindgesprek[' + index + ']', kindgesprek);
      });
    }

    if (
      leerprofielDropDownValue.value() == undefined ||
      leerprofielDropDownValue.value().length == 0
    ) {
      form.append('leerprofiel', '');
    } else {
      leerprofielDropDownValue.value().forEach((leerprofiel, index) => {
        form.append('leerprofiel[' + index + ']', leerprofiel);
      });
    }

    form.append(
      'beschrijving',
      document.getElementById('informativeBookBeschrijving').value
    );

    form.append(
      'extraInformatie',
      document.getElementById('informativeBookExtraInformatie').value
    );

    form.append(
      'jaarUitgave',
      document.getElementById('informativeBookJaarUitgave').value
    );
    if (uitleenbaarDropDownValue.value() != undefined) {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (
      document.getElementById('informativeBookAfbeelding').files.length == 1
    ) {
      form.append(
        'afbeelding',
        document.getElementById('informativeBookAfbeelding').files[0]
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('createInformativeBook').value = 'Voeg toe';
      document.getElementById('createInformativeBook').disabled = false;
    } else {
      document.getElementById('createInformativeBook').value = 'Updating...';
      document.getElementById('createInformativeBook').disabled = true;
      createInformativeBook(form);
    }
  });
}
if (updateInformativeBookBtn) {
  // Initialise the dropdowns and sliders
  const role = document.getElementById('role').value;
  let uitleenbaarDropDownValue;
  let locatieDropDownValue;
  let kindgesprekDropDownValue;
  let leerprofielDropDownValue;

  if (role === 'admin') {
    const locatie = locatieDropDown.dataset.informatiefboekLocatie;
    locatieDropDownValue = createLocatieDropDown(locatieDropDown, locatie);

    const uitleenbaar = uitleenbaarDropDown.dataset.informatiefboekUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );

    const kindgesprek = kindgesprekDropDown.dataset.informatiefboekindgesprek;
    kindgesprekDropDownValue = createKindGesprekDropDown(
      kindgesprekDropDown,
      kindgesprek
    );

    const leerprofiel = leerprofielDropDown.dataset.informatiefboekLeerprofiel;
    leerprofielDropDownValue = createLeerprofielDropDown(
      leerprofielDropDown,
      leerprofiel
    );
  }

  if (role === 'user') {
    const uitleenbaar = uitleenbaarDropDown.dataset.informatiefboekUitleenbaar;
    uitleenbaarDropDownValue = createUitleenbaarDropDown(
      uitleenbaarDropDown,
      uitleenbaar
    );
  }

  updateInformativeBookBtn.addEventListener('submit', e => {
    e.preventDefault();

    const errorMessages = [];

    const form = new FormData();
    form.append('_id', document.getElementById('id').value);
    form.append('role', role);

    if (role === 'admin') {
      if (document.getElementById('informativeBookNaam').value != '') {
        form.append(
          'naam',
          document.getElementById('informativeBookNaam').value
        );
      } else {
        errorMessages.push('Er dient een naam te worden opgegeven.');
      }

      if (locatieDropDownValue.value() != '') {
        form.append('locatie', locatieDropDownValue.value());
      } else {
        errorMessages.push('Er dient een locatie te worden opgegeven.');
      }
      form.append('kindgesprek', kindgesprekDropDownValue.value());
      form.append('leerprofiel', leerprofielDropDownValue.value());

      form.append(
        'beschrijving',
        document.getElementById('informativeBookBeschrijving').value
      );

      form.append(
        'jaarUitgave',
        document.getElementById('informativeBookJaarUitgave').value
      );

      if (
        document.getElementById('informativeBookAfbeelding').files.length == 1
      ) {
        form.append(
          'afbeelding',
          document.getElementById('informativeBookAfbeelding').files[0]
        );
      }
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (role === 'user') {
      form.append('uitleenbaar', uitleenbaarDropDownValue.value());
    }

    if (role === 'user' || role === 'admin') {
      form.append(
        'extraInformatie',
        document.getElementById('informativeBookExtraInformatie').value
      );
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('updateInformativeBook').value = 'Pas aan';
      document.getElementById('updateInformativeBook').disabled = false;
    } else {
      document.getElementById('updateInformativeBook').value = 'Updating...';
      document.getElementById('updateInformativeBook').disabled = true;
      updateInformativeBook(form);
    }
  });
}

deleteInformativeBookBtns.forEach(deleteInformativeBookBtn => {
  if (deleteInformativeBookBtn) {
    deleteInformativeBookBtn.addEventListener('click', e => {
      const { informativebookId } = e.target.dataset;
      const choice = confirm(e.target.dataset.confirm);

      if (choice) {
        deleteInformativeBook(informativebookId);
      }
    });
  }
});

// ***** INFORMATIVEBOOKRESERVATION BUTTONS *****
if (informativeBookReservationBtn) {
  informativeBookReservationBtn.addEventListener('click', e => {
    const { informativebookId, userId } = e.target.dataset;
    createInformativeBookReservation(userId, informativebookId);
  });
}

deleteInformativeBookReservationBtns.forEach(
  deleteInformativeBookReservationBtn => {
    if (deleteInformativeBookReservationBtn) {
      deleteInformativeBookReservationBtn.addEventListener('click', e => {
        const {
          informativebookbookingId,
          informativebookId
        } = e.currentTarget.dataset;

        deleteInformativeBookReservation(
          informativebookbookingId,
          informativebookId
        );
      });
    }
  }
);

// ***** USER BUTTONS *****
if (createUserBtn) {
  let userDepartmentDropDownValue = createDepartmentDropDown(
    userDepartmentDropDown
  );

  createUserBtn.addEventListener('submit', async e => {
    e.preventDefault();
    const errorMessages = [];
    const form = new FormData();

    if (document.getElementById('userNaam').value != '') {
      form.append('name', document.getElementById('userNaam').value);
    } else {
      errorMessages.push('Er dient een naam te worden opgegeven.');
    }

    if (document.getElementById('userEmail').value != '') {
      form.append('email', document.getElementById('userEmail').value);
    } else {
      errorMessages.push('Er dient een e-mail te worden opgegeven.');
    }

    if (
      userDepartmentDropDownValue.value() == undefined ||
      userDepartmentDropDownValue.value().length == 0
    ) {
      form.append('department', '');
    } else {
      userDepartmentDropDownValue.value().forEach(department => {
        form.append('department', department);
      });
    }

    const password = document.getElementById('userPaswoord').value;
    const passwordConfirm = document.getElementById('userPaswoordBevestig')
      .value;
    if (
      password != passwordConfirm ||
      password == '' ||
      passwordConfirm == ''
    ) {
      errorMessages.push(
        'De paswoorden moeten gelijk zijn en minstens uit 8 tekens bestaan.'
      );
    } else {
      form.append('password', password);
      form.append('passwordConfirm', passwordConfirm);
    }

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('createUser').value = 'Voeg toe';
      document.getElementById('createUser').disabled = false;
    } else {
      document.getElementById('createUser').value = 'Updating...';
      document.getElementById('createUser').disabled = true;
      await createUser(form);
    }
  });
}

if (updateUserBtn) {
  const userRol = userUpdateRolDropDown.dataset.userRol;
  let userUpdateRolDropDownValue = createRolDropDown(
    userUpdateRolDropDown,
    userRol
  );

  const userDepartment = userUpdateDepartmentDropDown.dataset.userDepartment;
  let userUpdateDepartmentDropDownValue = createDepartmentDropDown(
    userUpdateDepartmentDropDown,
    userDepartment
  );

  updateUserBtn.addEventListener('submit', e => {
    e.preventDefault();

    const errorMessages = [];

    const form = new FormData();

    form.append('_id', document.getElementById('id').value);

    if (document.getElementById('userNaam').value != '') {
      form.append('name', document.getElementById('userNaam').value);
    } else {
      errorMessages.push('Er dient een naam te worden gegeven.');
    }

    if (
      userUpdateDepartmentDropDownValue.value() == undefined ||
      userUpdateDepartmentDropDownValue.value().length == 0
    ) {
      form.append('department', '');
    } else {
      userUpdateDepartmentDropDownValue.value().forEach(department => {
        form.append('department', department);
      });
    }

    form.append('role', userUpdateRolDropDownValue.value());

    if (errorMessages.length != 0) {
      showAlert('error', errorMessages.join(' '), 5);
      document.getElementById('updateUser').value = 'Pas aan';
      document.getElementById('updateUser').disabled = false;
    } else {
      document.getElementById('updateUser').value = 'Updating...';
      document.getElementById('updateUser').disabled = true;
      updateUser(form);
    }
  });
}

deleteUserBtns.forEach(deleteUserBtn => {
  if (deleteUserBtn) {
    deleteUserBtn.addEventListener('click', e => {
      const { userId } = e.target.dataset;
      const choice = confirm(e.target.dataset.confirm);
      if (choice) {
        deleteUser(userId);
      }
    });
  }
});

// ***** FILTERS *****
// TODO: voeg sorteren toe
if (filterVirtualRealityAppsBtn) {
  filterVirtualRealityAppsBtn.addEventListener(
    'click',
    e => {
      e.preventDefault();

      const beschrijving = document.getElementById(
        'virtualRealityApps-description-search'
      ).value;


      const vakken = document.getElementById('virtualRealityApps-courses-search');
      const vakkenIndex = vakken.selectedIndex;
      const vakkenValue = vakken.options[vakkenIndex].value;

      Cookies.set('filter-beschrijving-virtualRealityApp', beschrijving);
      Cookies.set('filter-vak-virtualRealityApp', vakkenIndex);

      window.location = `/virtualRealityApps/overview?naam=${beschrijving}&beschrijving=${beschrijving}&vak=${vakkenValue}`;
    },
    window.addEventListener('load', () => {
      const beschrijvingCookie = Cookies.get(
        'filter-beschrijving-virtualRealityApp',
        {
          expires: 1 / 24
        }
      );
      if (beschrijvingCookie != null) {
        document.getElementById(
          'virtualRealityApps-description-search'
        ).value = beschrijvingCookie;
      }
      document.getElementById(
        'virtualRealityApps-courses-search'
      ).selectedIndex = Cookies.get('filter-vak-virtualRealityApp', {
        expires: 1 / 24
      });
    })
  );
}

if (resetFilterVirtualRealityAppsBtn) {
  resetFilterVirtualRealityAppsBtn.addEventListener('click', e => {
    e.preventDefault();
    //Set values to default
    document.getElementById('virtualRealityApps-description-search').value = '';
    document.getElementById(
      'virtualRealityApps-courses-search'
    ).selectedIndex = 0;

    //Remove cookies
    Cookies.remove('filter-beschrijving-virtualRealityApp');
    Cookies.remove('filter-vak-virtualRealityApp');

    window.location = '/virtualRealityApps/overview';
  });
}

if (filterDidacticMaterialBtn) {
  filterDidacticMaterialBtn.addEventListener(
    'click',
    e => {
      e.preventDefault();

      const beschrijving = document.getElementById(
        'didacticMaterial-description-search'
      ).value;

      const niveau = document.getElementById('didacticMaterial-grade-search');
      const niveauIndex = niveau.selectedIndex;
      const niveauValue = niveau.options[niveauIndex].value;
      const locatie = document.getElementById(
        'didacticMaterial-location-search'
      );
      const locatieIndex = locatie.selectedIndex;
      const locatieValue = locatie.options[locatieIndex].value;
      const vakken = document.getElementById('didacticMaterial-courses-search');
      const vakkenIndex = vakken.selectedIndex;
      const vakkenValue = vakken.options[vakkenIndex].value;

      Cookies.set('filter-beschrijving-didactischMateriaal', beschrijving);
      Cookies.set('filter-niveau-didactischMateriaal', niveauIndex);
      Cookies.set('filter-locatie-didactischMateriaal', locatieIndex);
      Cookies.set('filter-vak-didactischMateriaal', vakkenIndex);

      window.location = `/didacticMaterials/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&vak=${vakkenValue}&niveau=${niveauValue}`;
    },
    window.addEventListener('load', () => {
      const beschrijvingCookie = Cookies.get(
        'filter-beschrijving-didactischMateriaal',
        {
          expires: 1 / 24
        }
      );
      if (beschrijvingCookie != null) {
        document.getElementById(
          'didacticMaterial-description-search'
        ).value = beschrijvingCookie;
      }

      document.getElementById(
        'didacticMaterial-location-search'
      ).selectedIndex = Cookies.get('filter-locatie-didactischMateriaal', {
        expires: 1 / 24
      });
      document.getElementById(
        'didacticMaterial-courses-search'
      ).selectedIndex = Cookies.get('filter-vak-didactischMateriaal', {
        expires: 1 / 24
      });
      document.getElementById(
        'didacticMaterial-grade-search'
      ).selectedIndex = Cookies.get('filter-niveau-didactischMateriaal', {
        expires: 1 / 24
      });
    })
  );
}

if (resetFilterDidacticMaterialBtn) {
  resetFilterDidacticMaterialBtn.addEventListener('click', e => {
    e.preventDefault();
    //Set values to default
    document.getElementById('didacticMaterial-description-search').value = '';
    document.getElementById('didacticMaterial-grade-search').selectedIndex = 0;
    document.getElementById(
      'didacticMaterial-location-search'
    ).selectedIndex = 0;
    document.getElementById(
      'didacticMaterial-courses-search'
    ).selectedIndex = 0;

    //Remove cookies
    Cookies.remove('filter-beschrijving-didactischMateriaal');
    Cookies.remove('filter-locatie-didactischMateriaal');
    Cookies.remove('filter-niveau-didactischMateriaal');
    Cookies.remove('filter-vak-didactischMateriaal');

    window.location = '/didacticMaterials/overview';
  });
}

if (filterGamesBtn) {
  filterGamesBtn.addEventListener(
    'click',
    e => {
      e.preventDefault();

      const beschrijving = document.getElementById('games-description-search')
        .value;
      const location = document.getElementById('games-location-search');
      const locationIndex = location.selectedIndex;
      const locationValue = location.options[locationIndex].value;
      const vakken = document.getElementById('games-courses-search');
      const vakkenIndex = vakken.selectedIndex;
      const vakkenValue = vakken.options[vakkenIndex].value;
      const executieveFuncties = document.getElementById('games-executieve-functies-search');
      const executieveFunctiesIndex = executieveFuncties.selectedIndex;
      const executieveFunctiesValue = executieveFuncties.options[executieveFunctiesIndex].value;
      const leerprofiel = document.getElementById('games-leerprofiel-search');
      const leerprofielIndex = leerprofiel.selectedIndex;
      const leerprofielValue = leerprofiel.options[leerprofielIndex].value;
      const minLeeftijd = leeftijdSlider.noUiSlider.get()[0];
      const maxLeeftijd = leeftijdSlider.noUiSlider.get()[1];
      const minSpelers = gameSpelersSlider.noUiSlider.get()[0];
      const maxSpelers = gameSpelersSlider.noUiSlider.get()[1];

      Cookies.set('filter-beschrijving', beschrijving);
      Cookies.set('filter-location', locationIndex);
      Cookies.set('filter-vakken', vakkenIndex);
      Cookies.set('filter-leerprofiel', leerprofielIndex);
      Cookies.set('filter-executieveFuncties', executieveFunctiesIndex);

      Cookies.set(
        'filter-leeftijd',
        JSON.stringify([minLeeftijd, maxLeeftijd])
      );
      Cookies.set('filter-spelers', JSON.stringify([minSpelers, maxSpelers]));

      window.location = `/games/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locationValue}&executieveFunctie=${executieveFunctiesValue}&vak=${vakkenValue}&leerprofiel=${leerprofielValue}&minLeeftijd=${minLeeftijd}&maxLeeftijd=${maxLeeftijd}&minSpelers=${minSpelers}&maxSpelers=${maxSpelers}`;


    },
    window.addEventListener('load', () => {
      const beschrijvingCookie = Cookies.get('filter-beschrijving', {
        expires: 1 / 24
      });
      if (beschrijvingCookie != null) {
        document.getElementById(
          'games-description-search'
        ).value = beschrijvingCookie;
      }

      document.getElementById(
        'games-location-search'
      ).selectedIndex = Cookies.get('filter-location', { expires: 1 / 24 });
      document.getElementById(
        'games-courses-search'
      ).selectedIndex = Cookies.get('filter-vakken', { expires: 1 / 24 });
      document.getElementById(
        'games-leerprofiel-search'
      ).selectedIndex = Cookies.get('filter-leerprofiel', { expires: 1 / 24 });
      document.getElementById(
        'games-executieve-functies-search'
      ).selectedIndex = Cookies.get('filter-executieveFuncties', { expires: 1 / 24 });


      const leeftijdCookieValue = Cookies.get('filter-leeftijd', {
        expires: 1 / 24
      });

      if (leeftijdCookieValue != null) {
        const leeftijdCookie = JSON.parse(leeftijdCookieValue);

        if (
          parseInt(leeftijdCookie[0], 10) != 1 ||
          parseInt(leeftijdCookie[1], 10) != 99
        ) {
          leeftijdSlider.noUiSlider.set([
            parseInt(leeftijdCookie[0], 10),
            parseInt(leeftijdCookie[1], 10)
          ]);
        }
      }

      const spelersCookieValue = Cookies.get('filter-spelers', {
        expires: 1 / 24
      });
      if (spelersCookieValue != null) {
        const spelersCookie = JSON.parse(spelersCookieValue);
        if (
          parseInt(spelersCookie[0], 10) != 1 ||
          parseInt(spelersCookie[1], 10) != 10
        ) {
          gameSpelersSlider.noUiSlider.set([
            parseInt(spelersCookie[0], 10),
            parseInt(spelersCookie[1], 10)
          ]);
        }
      }
    })
  );
}

if (resetFilterGamesBtn) {
  resetFilterGamesBtn.addEventListener('click', e => {
    e.preventDefault();
    //Set values to default
    document.getElementById('games-description-search').value = '';
    document.getElementById('games-location-search').selectedIndex = 0;
    document.getElementById('games-courses-search').selectedIndex = 0;
    document.getElementById('games-leerprofiel-search').selectedIndex = 0;
    document.getElementById('games-executieve-functies-search').selectedIndex = 0;
    leeftijdSlider.noUiSlider.reset();
    gameSpelersSlider.noUiSlider.reset();

    //Remove cookies
    Cookies.remove('filter-beschrijving');
    Cookies.remove('filter-location');
    Cookies.remove('filter-vakken');
    Cookies.remove('filter-leerprofiel');
    Cookies.remove('filter-leeftijd');
    Cookies.remove('filter-spelers');
    Cookies.remove('filter-executieveFuncties')

    window.location = '/games/overview';
  });
}

if (filterLearningBookBtn) {
  filterLearningBookBtn.addEventListener(
    'click',
    e => {
      e.preventDefault();

      const beschrijving = document.getElementById(
        'learningBook-description-search'
      ).value;

      const niveau = document.getElementById('learningBook-grade-search');
      const niveauIndex = niveau.selectedIndex;
      const niveauValue = niveau.options[niveauIndex].value;
      const locatie = document.getElementById('learningBook-location-search');
      const locatieIndex = locatie.selectedIndex;
      const locatieValue = locatie.options[locatieIndex].value;
      const vakken = document.getElementById('learningBook-courses-search');
      const vakkenIndex = vakken.selectedIndex;
      const vakkenValue = vakken.options[vakkenIndex].value;

      Cookies.set('filter-beschrijving-leerBoek', beschrijving);
      Cookies.set('filter-niveau-leerBoek', niveauIndex);
      Cookies.set('filter-locatie-leerBoek', locatieIndex);
      Cookies.set('filter-vak-leerBoek', vakkenIndex);

      window.location = `/learningBooks/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&vak=${vakkenValue}&niveau=${niveauValue}`;
    },
    window.addEventListener('load', () => {
      const beschrijvingCookie = Cookies.get('filter-beschrijving-leerBoek', {
        expires: 1 / 24
      });
      if (beschrijvingCookie != null) {
        document.getElementById(
          'learningBook-description-search'
        ).value = beschrijvingCookie;
      }

      document.getElementById(
        'learningBook-location-search'
      ).selectedIndex = Cookies.get('filter-locatie-leerBoek', {
        expires: 1 / 24
      });
      document.getElementById(
        'learningBook-courses-search'
      ).selectedIndex = Cookies.get('filter-vak-leerBoek', {
        expires: 1 / 24
      });
      document.getElementById(
        'learningBook-grade-search'
      ).selectedIndex = Cookies.get('filter-niveau-leerBoek', {
        expires: 1 / 24
      });
    })
  );
}

if (resetFilterLearningBookBtn) {
  resetFilterLearningBookBtn.addEventListener('click', e => {
    e.preventDefault();
    //Set values to default
    document.getElementById('learningBook-description-search').value = '';
    document.getElementById('learningBook-location-search').selectedIndex = 0;
    document.getElementById('learningBook-courses-search').selectedIndex = 0;
    document.getElementById('learningBook-grade-search').selectedIndex = 0;

    //Remove cookies
    Cookies.remove('filter-beschrijving-leerBoek');
    Cookies.remove('filter-locatie-leerBoek');
    Cookies.remove('filter-vak-leerBoek');
    Cookies.remove('filter-niveau-leerBoek');

    window.location = '/learningBooks/overview';
  });
}

if (filterInformativeBookBtn) {
  filterInformativeBookBtn.addEventListener(
    'click',
    e => {
      e.preventDefault();

      const beschrijving = document.getElementById(
        'informativeBook-description-search'
      ).value;
      const locatie = document.getElementById(
        'informativeBook-location-search'
      );
      const locatieIndex = locatie.selectedIndex;
      const locatieValue = locatie.options[locatieIndex].value;
      const leerprofiel = document.getElementById(
        'informativeBook-leerprofiel-search'
      );
      const leerprofielIndex = leerprofiel.selectedIndex;
      const leerprofielValue = leerprofiel.options[leerprofielIndex].value;

      Cookies.set('filter-beschrijving-informatiefBoek', beschrijving);
      Cookies.set('filter-locatie-informatiefBoek', locatieIndex);
      Cookies.set('filter-leerprofiel-informatiefBoek', leerprofielIndex);

      window.location = `/informativeBooks/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&leerprofiel=${leerprofielValue}`;
    },
    window.addEventListener('load', () => {
      const beschrijvingCookie = Cookies.get(
        'filter-beschrijving-informatiefBoek',
        {
          expires: 1 / 24
        }
      );
      if (beschrijvingCookie != null) {
        document.getElementById(
          'informativeBook-description-search'
        ).value = beschrijvingCookie;
      }

      document.getElementById(
        'informativeBook-location-search'
      ).selectedIndex = Cookies.get('filter-locatie-informatiefBoek', {
        expires: 1 / 24
      });

      document.getElementById(
        'informativeBook-leerprofiel-search'
      ).selectedIndex = Cookies.get('filter-leerprofiel-informatiefBoek', {
        expires: 1 / 24
      });
    })
  );
}

if (resetFilterInformativeBookBtn) {
  resetFilterInformativeBookBtn.addEventListener('click', e => {
    e.preventDefault();
    //Set values to default
    document.getElementById('informativeBook-description-search').value = '';
    document.getElementById(
      'informativeBook-location-search'
    ).selectedIndex = 0;
    document.getElementById(
      'informativeBook-leerprofiel-search'
    ).selectedIndex = 0;

    //Remove cookies
    Cookies.remove('filter-beschrijving-informatiefBoek');
    Cookies.remove('filter-locatie-informatiefBoek');
    Cookies.remove('filter-leerprofiel-informatiefBoek');

    window.location = '/informativeBooks/overview';
  });
}

if (filterReadingAndPictureBookBtn) {
  filterReadingAndPictureBookBtn.addEventListener(
    'click',
    e => {
      e.preventDefault();

      const beschrijving = document.getElementById(
        'readingAndPictureBook-description-search'
      ).value;
      const locatie = document.getElementById(
        'readingAndPictureBook-location-search'
      );
      const locatieIndex = locatie.selectedIndex;
      const locatieValue = locatie.options[locatieIndex].value;

      const minLeeftijd = leeftijdSlider.noUiSlider.get()[0];
      const maxLeeftijd = leeftijdSlider.noUiSlider.get()[1];

      Cookies.set('filter-beschrijving-readingAndPictureBook', beschrijving);
      Cookies.set('filter-locatie-readingAndPictureBook', locatieIndex);
      Cookies.set(
        'filter-leeftijd-readingAndPictureBook',
        JSON.stringify([minLeeftijd, maxLeeftijd])
      );

      window.location = `/readingAndPictureBooks/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&minLeeftijd=${minLeeftijd}&maxLeeftijd=${maxLeeftijd}`;
    },
    window.addEventListener('load', () => {
      const beschrijvingCookie = Cookies.get(
        'filter-beschrijving-readingAndPictureBook',
        {
          expires: 1 / 24
        }
      );
      if (beschrijvingCookie != null) {
        document.getElementById(
          'readingAndPictureBook-description-search'
        ).value = beschrijvingCookie;
      }

      document.getElementById(
        'readingAndPictureBook-location-search'
      ).selectedIndex = Cookies.get('filter-locatie-readingAndPictureBook', {
        expires: 1 / 24
      });

      const leeftijdCookieValue = Cookies.get(
        'filter-leeftijd-readingAndPictureBook',
        {
          expires: 1 / 24
        }
      );

      if (leeftijdCookieValue != null) {
        const leeftijdCookie = JSON.parse(leeftijdCookieValue);

        if (
          parseInt(leeftijdCookie[0], 10) != 1 ||
          parseInt(leeftijdCookie[1], 10) != 99
        ) {
          leeftijdSlider.noUiSlider.set([
            parseInt(leeftijdCookie[0], 10),
            parseInt(leeftijdCookie[1], 10)
          ]);
        }
      }
    })
  );
}

if (resetFilterReadingAndPictureBookBtn) {
  resetFilterReadingAndPictureBookBtn.addEventListener('click', e => {
    e.preventDefault();
    //Set values to default
    document.getElementById('readingAndPictureBook-description-search').value =
      '';
    document.getElementById(
      'readingAndPictureBook-location-search'
    ).selectedIndex = 0;
    leeftijdSlider.noUiSlider.reset();

    //Remove cookies
    Cookies.remove('filter-beschrijving-readingAndPictureBook');
    Cookies.remove('filter-locatie-readingAndPictureBook');
    Cookies.remove('filter-leeftijd-readingAndPictureBook');

    window.location = '/readingAndPictureBooks/overview';
  });
}

// **** COOKIES **** //
const consentPropertyName = 'zhssg_c_c';

const shouldShowPopup = () => !Cookies.get(consentPropertyName);
const acceptCookieStorage = () =>
  Cookies.set(consentPropertyName, true, { expires: 365 });
const declineCookieStorage = () =>
  Cookies.set(consentPropertyName, false, { expires: 365 });

window.onload = () => {
  const consentPopup = document.getElementById('consent-popup');
  const acceptBtn = document.getElementById('accept');
  const declineBtn = document.getElementById('decline');

  const acceptFn = event => {
    acceptCookieStorage();
    //location.reload();
    consentPopup.classList.add('hidden');
  };

  const declineFn = event => {
    declineCookieStorage();
    consentPopup.classList.add('hidden');
  };

  acceptBtn.addEventListener('click', acceptFn);
  declineBtn.addEventListener('click', declineFn);

  if (shouldShowPopup()) {
    setTimeout(() => {
      consentPopup.classList.remove('hidden');
    }, 5000);
  }

  // When first loaded or consent is false, no Cookies are allowed
  if (
    Cookies.get(consentPropertyName) === 'false' ||
    Cookies.get(consentPropertyName) === null
  ) {
    // This window property must be set before any calls to the ga() command queue are made, and it must be set on each page for which you want to disable Google Analytics measurement. If the property is not set or set to false then measurement will work as usual.
    window['ga-disable-UA-98627475-6'] = true;
  }
};

// **** PAGINATION **** //
const nextBtn = document.getElementById('page-next');
const backBtn = document.getElementById('page-back');
const pagination = document.querySelector('.pagination');
if (nextBtn) {
  nextBtn.addEventListener('click', e => {
    e.preventDefault();

    const url = window.location.href;
    let page = new Number(
      new URLSearchParams(window.location.search).get('page')
    );

    if (page != 0) {
      page += 1;
    } else {
      page = 2;
    }

    paginationSearchQuery(url, page);
  });
}

if (backBtn) {
  backBtn.addEventListener('click', e => {
    e.preventDefault();

    const url = window.location.href;
    let page = new Number(
      new URLSearchParams(window.location.search).get('page')
    );

    if (page != 0) {
      page -= 1;
    }

    paginationSearchQuery(url, page);
  });
}

function paginationSearchQuery(url, page) {
  const readingAndPictureBooks = url.indexOf('readingAndPictureBooks/overview');
  const learningBooks = url.indexOf('learningBooks/overview');
  const informativeBooks = url.indexOf('informativeBooks/overview');
  const games = url.indexOf('games/overview');
  const didacticMaterials = url.indexOf('didacticMaterials/overview');
  const virtualRealityApps = url.indexOf('virtualRealityApps/overview');

  if (readingAndPictureBooks > -1) {
    const beschrijving = document.getElementById(
      'readingAndPictureBook-description-search'
    ).value;
    const locatie = document.getElementById(
      'readingAndPictureBook-location-search'
    );
    const locatieIndex = locatie.selectedIndex;
    const locatieValue = locatie.options[locatieIndex].value;

    const minLeeftijd = leeftijdSlider.noUiSlider.get()[0];
    const maxLeeftijd = leeftijdSlider.noUiSlider.get()[1];

    window.location = `/readingAndPictureBooks/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&minLeeftijd=${minLeeftijd}&maxLeeftijd=${maxLeeftijd}&page=${page}`;
  }

  if (learningBooks > -1) {
    const beschrijving = document.getElementById(
      'learningBook-description-search'
    ).value;
    const niveau = document.getElementById('learningBook-grade-search');
    const niveauIndex = niveau.selectedIndex;
    const niveauValue = niveau.options[niveauIndex].value;
    const locatie = document.getElementById('learningBook-location-search');
    const locatieIndex = locatie.selectedIndex;
    const locatieValue = locatie.options[locatieIndex].value;
    const vakken = document.getElementById('learningBook-courses-search');
    const vakkenIndex = vakken.selectedIndex;
    const vakkenValue = vakken.options[vakkenIndex].value;

    window.location = `/learningBooks/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&vak=${vakkenValue}&niveau=${niveauValue}&page=${page}`;
  }

  if (informativeBooks > -1) {
    const beschrijving = document.getElementById(
      'informativeBook-description-search'
    ).value;
    const locatie = document.getElementById('informativeBook-location-search');
    const locatieIndex = locatie.selectedIndex;
    const locatieValue = locatie.options[locatieIndex].value;
    const leerprofiel = document.getElementById(
      'informativeBook-leerprofiel-search'
    );
    const leerprofielIndex = leerprofiel.selectedIndex;
    const leerprofielValue = leerprofiel.options[leerprofielIndex].value;

    window.location = `/informativeBooks/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&leerprofiel=${leerprofielValue}&page=${page}`;
  }
  if (games > -1) {
    const beschrijving = document.getElementById('games-description-search')
      .value;
    const location = document.getElementById('games-location-search');
    const locationIndex = location.selectedIndex;
    const locationValue = location.options[locationIndex].value;
    const vakken = document.getElementById('games-courses-search');
    const vakkenIndex = vakken.selectedIndex;
    const vakkenValue = vakken.options[vakkenIndex].value;
    const leerprofiel = document.getElementById('games-leerprofiel-search');
    const leerprofielIndex = leerprofiel.selectedIndex;
    const leerprofielValue = leerprofiel.options[leerprofielIndex].value;
    const minLeeftijd = leeftijdSlider.noUiSlider.get()[0];
    const maxLeeftijd = leeftijdSlider.noUiSlider.get()[1];
    const minSpelers = gameSpelersSlider.noUiSlider.get()[0];
    const maxSpelers = gameSpelersSlider.noUiSlider.get()[1];
    const executieveFuncties = document.getElementById('games-executieve-functies-search');
    const executieveFunctiesIndex = executieveFuncties.selectedIndex;
    const executieveFunctiesValue = executieveFuncties.options[executieveFunctiesIndex].value;

    window.location = `/games/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locationValue}&executieveFuncties=${executieveFunctiesValue}&vak=${vakkenValue}&leerprofiel=${leerprofielValue}&minLeeftijd=${minLeeftijd}&maxLeeftijd=${maxLeeftijd}&minSpelers=${minSpelers}&maxSpelers=${maxSpelers}&page=${page}`;
  }
  if (didacticMaterials > -1) {
    const beschrijving = document.getElementById(
      'didacticMaterial-description-search'
    ).value;

    const niveau = document.getElementById('didacticMaterial-grade-search');
    const niveauIndex = niveau.selectedIndex;
    const niveauValue = niveau.options[niveauIndex].value;
    const locatie = document.getElementById('didacticMaterial-location-search');
    const locatieIndex = locatie.selectedIndex;
    const locatieValue = locatie.options[locatieIndex].value;
    const vakken = document.getElementById('didacticMaterial-courses-search');
    const vakkenIndex = vakken.selectedIndex;
    const vakkenValue = vakken.options[vakkenIndex].value;

    window.location = `/didacticMaterials/overview?naam=${beschrijving}&beschrijving=${beschrijving}&locatie=${locatieValue}&vak=${vakkenValue}&niveau=${niveauValue}&page=${page}`;
  }

  if (virtualRealityApps > -1) {
    const beschrijving = document.getElementById(
      'virtualRealityApps-description-search'
    ).value;

    const vakken = document.getElementById('didacticMaterial-courses-search');
    const vakkenIndex = vakken.selectedIndex;
    const vakkenValue = vakken.options[vakkenIndex].value;

    window.location = `/didacticMaterials/overview?naam=${beschrijving}&beschrijving=${beschrijving}&vak=${vakkenValue}&page=${page}`;
  }
}

if (pagination) {
  window.addEventListener('load', () => {
    const page = new URLSearchParams(window.location.search).get('page');
    const pages = document.querySelector('.pagination').dataset.pages;

    if (page) {
      document.getElementById('insert-page-number').textContent = page;
    }

    if (page == 1 || page == null) {
      backBtn.remove();
    }
    if (page == pages || pages == 1) {
      nextBtn.remove();
    }
    if (pages == 0) {
      nextBtn.remove();
      document.querySelector('.pagination').remove();
    }
  });
}