/* eslint-disable */
import axios from 'axios';
import { showAlert } from './alerts';

export const createGameReservation = async (userId, gameId) => {
  try {
    const gameReservation = axios({
      method: 'POST',
      url: '/api/v1/gameBookings',
      data: {
        user: userId,
        game: gameId
      }
    });

    const changeGameStatus = axios({
      method: 'PATCH',
      url: `/api/v1/games/${gameId}`,
      data: {
        uitgeleend: true
      }
    });
    const res = await axios.all([gameReservation, changeGameStatus]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het spel werd toegevoegd aan uitleningen');
      window.setTimeout(() => {
        location.assign('/account/reservations/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het ontlenen');
    window.setTimeout(() => {
      location.reload();
    }, 1500);
  }
};

export const createDidacticMaterialReservation = async (
  userId,
  didacticMaterialId
) => {
  try {
    const didacticMaterialBooking = axios({
      method: 'POST',
      url: '/api/v1/didacticMaterialBookings',
      data: {
        user: userId,
        didacticMaterial: didacticMaterialId
      }
    });

    const changeDidacticMaterialStatus = axios({
      method: 'PATCH',
      url: `/api/v1/didacticMaterials/${didacticMaterialId}`,
      data: {
        uitgeleend: true
      }
    });
    const res = await axios.all([
      didacticMaterialBooking,
      changeDidacticMaterialStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert(
        'success',
        'Het didactisch materiaal werd toegevoegd aan uitleningen'
      );
      window.setTimeout(() => {
        location.assign('/account/reservations/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het ontlenen');
    window.setTimeout(() => {
      location.reload();
    }, 1500);
  }
};

export const createLearningBookReservation = async (userId, learningBookId) => {
  try {
    const learningBookBooking = axios({
      method: 'POST',
      url: '/api/v1/learningBookBookings',
      data: {
        user: userId,
        learningBook: learningBookId
      }
    });

    const changeLearningBookStatus = axios({
      method: 'PATCH',
      url: `/api/v1/learningBooks/${learningBookId}`,
      data: {
        uitgeleend: true
      }
    });
    const res = await axios.all([
      learningBookBooking,
      changeLearningBookStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het leerboek werd toegevoegd aan uitleningen');
      window.setTimeout(() => {
        location.assign('/account/reservations/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het ontlenen');
    window.setTimeout(() => {
      location.reload();
    }, 1500);
  }
};

export const deleteLearningBookReservation = async (
  learningBookBookingId,
  learningBookId
) => {
  try {
    const deleteLearningBookReservation = axios({
      method: 'PATCH',
      url: `/api/v1/learningBookBookings/${learningBookBookingId}`,
      data: {
        loan: false
      }
    });

    const changeLearningBookStatus = axios({
      method: 'PATCH',
      url: `/api/v1/learningBooks/${learningBookId}`,
      data: {
        uitgeleend: false
      }
    });

    const res = await axios.all([
      deleteLearningBookReservation,
      changeLearningBookStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het leerboek werd teruggebracht');
      window.setTimeout(() => {
        location.assign('/account/reservations/learningBooks');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het inleveren');
  }
};

export const deleteGameReservation = async (gameBookingId, gameId) => {
  try {
    const deleteGameReservation = axios({
      method: 'PATCH',
      url: `/api/v1/gameBookings/${gameBookingId}`,
      data: {
        loan: false
      }
    });

    const changeGameStatus = axios({
      method: 'PATCH',
      url: `/api/v1/games/${gameId}`,
      data: {
        uitgeleend: false
      }
    });

    const res = await axios.all([deleteGameReservation, changeGameStatus]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het spel werd teruggebracht');
      window.setTimeout(() => {
        location.assign('/account/reservations/games');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het inleveren');
  }
};

export const deleteDidacticMaterialReservation = async (
  didacticMaterialBookingId,
  didacticMaterialId
) => {
  try {
    const deleteDidacticMaterialBooking = axios({
      method: 'PATCH',
      url: `/api/v1/didacticMaterialBookings/${didacticMaterialBookingId}`,
      data: {
        loan: false
      }
    });

    const changeDidacticMaterialStatus = axios({
      method: 'PATCH',
      url: `/api/v1/didacticMaterials/${didacticMaterialId}`,
      data: {
        uitgeleend: false
      }
    });

    const res = await axios.all([
      deleteDidacticMaterialBooking,
      changeDidacticMaterialStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het didactisch materiaal werd teruggebracht');
      window.setTimeout(() => {
        location.assign('/account/reservations/didacticMaterials');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het inleveren');
  }
};

export const updateGameReservation = async gamebookingId => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/gameBookings/${gamebookingId}`,
      data: {
        prolongation: true,
        prolongationDate: Date.now()
      }
    });

    if ((res.data.status = 'success')) {
      showAlert('success', 'Het spel werd met succes verlengd voor 14 dagen');
      window.setTimeout(() => {
        location.assign('account/reservations/games');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het verlengen');
  }
};

export const createInformativeBookReservation = async (
  userId,
  informativeBookId
) => {
  try {
    const informativeBookBooking = axios({
      method: 'POST',
      url: '/api/v1/informativeBookBookings',
      data: {
        user: userId,
        informativeBook: informativeBookId
      }
    });

    const changeInformativeBookStatus = axios({
      method: 'PATCH',
      url: `/api/v1/informativeBooks/${informativeBookId}`,
      data: {
        uitgeleend: true
      }
    });
    const res = await axios.all([
      informativeBookBooking,
      changeInformativeBookStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert(
        'success',
        'Het informative boek werd toegevoegd aan uitleningen'
      );
      window.setTimeout(() => {
        location.assign('/account/reservations/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het ontlenen');
    window.setTimeout(() => {
      location.reload();
    }, 1500);
  }
};

export const deleteInformativeBookReservation = async (
  informativeBookBookingId,
  informativeBookId
) => {
  try {
    const deleteInformativeBookReservation = axios({
      method: 'PATCH',
      url: `/api/v1/informativeBookBookings/${informativeBookBookingId}`,
      data: {
        loan: false
      }
    });

    const changeInformativeBookStatus = axios({
      method: 'PATCH',
      url: `/api/v1/informativeBooks/${informativeBookId}`,
      data: {
        uitgeleend: false
      }
    });

    const res = await axios.all([
      deleteInformativeBookReservation,
      changeInformativeBookStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het informatieve boek werd teruggebracht');
      window.setTimeout(() => {
        location.assign('/account/reservations/informativeBooks');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het inleveren');
  }
};

export const createReadingAndPictureBookReservation = async (
  userId,
  readingAndPictureBookId
) => {
  try {
    const readingAndPictureBookBooking = axios({
      method: 'POST',
      url: '/api/v1/readingAndPictureBookBookings',
      data: {
        user: userId,
        readingAndPictureBook: readingAndPictureBookId
      }
    });

    const changeReadingAndPictureBookStatus = axios({
      method: 'PATCH',
      url: `/api/v1/readingAndPictureBooks/${readingAndPictureBookId}`,
      data: {
        uitgeleend: true
      }
    });
    const res = await axios.all([
      readingAndPictureBookBooking,
      changeReadingAndPictureBookStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert(
        'success',
        'Het lees- en prentenboek werd toegevoegd aan uitleningen'
      );
      window.setTimeout(() => {
        location.assign('/account/reservations/overview');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het ontlenen');
    window.setTimeout(() => {
      location.reload();
    }, 1500);
  }
};

export const deleteReadingAndPictureBookReservation = async (
  readBookBookingId,
  readingAndPictureBookId
) => {
  try {
    const deleteReadingAndPictureBookReservation = axios({
      method: 'PATCH',
      url: `/api/v1/readingAndPictureBookBookings/${readBookBookingId}`,
      data: {
        loan: false
      }
    });

    const changeReadingAndPictureBookStatus = axios({
      method: 'PATCH',
      url: `/api/v1/readingAndPictureBooks/${readingAndPictureBookId}`,
      data: {
        uitgeleend: false
      }
    });

    const res = await axios.all([
      deleteReadingAndPictureBookReservation,
      changeReadingAndPictureBookStatus
    ]);

    if (res[0].data.status === 'success' && res[1].data.status === 'success') {
      showAlert('success', 'Het lees- en prentenboek werd teruggebracht');
      window.setTimeout(() => {
        location.assign('/account/reservations/readingAndPictureBooks');
      }, 1500);
    }
  } catch (err) {
    showAlert('error', 'Fout tijdens het inleveren');
  }
};
