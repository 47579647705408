/* eslint-disable */
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';

export const createLinearSlider = (slider, minValue, maxValue) => {
  noUiSlider.create(slider, {
    start: [minValue, maxValue],
    connect: true,
    step: 1,
    tooltips: true,
    range: {
      min: minValue,
      max: maxValue
    },
    format: wNumb({
      decimals: 0
    })
  });
};

export const createNonLinearSlider = (
  slider,
  minValue,
  maxValue,
  nonLinearValue
) => {
  noUiSlider.create(slider, {
    start: [minValue, maxValue],
    connect: true,
    step: 1,
    tooltips: true,
    range: {
      min: [minValue],
      '50%': [nonLinearValue],
      max: [maxValue]
    },
    format: wNumb({
      decimals: 0
    })
  });
};
